import { Alert } from '@abyss/web/ui/Alert';
import find from 'lodash/find';
import React, { useState } from 'react';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useGetTokenOrUrlParams } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { getLagoonConfigValue } from '../../../../utils/providerSearch.utils';

export const ExpandedSearchRadiusBanner = () => {
  const urlParams = useGetTokenOrUrlParams();
  const [isVisible, setIsVisible] = useState(true);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();
  const allConfigs = useLagoon('config');
  const defaultSearchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const providerCountPreference = getLagoonConfigValue(
    allConfigs,
    'PROVIDER_COUNT_PREFERENCE_OTHER'
  );
  const expandedSearchRadiusDescription = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.EXPANDED_SEARCH_RADIUS_DESCRIPTION,
  });

  const isEnabledExpandedSearchRadiusBanner = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EXPANDED_SEARCH_RADIUS_BANNER
  );

  const showExpandedSearchRadiusBanner =
    isEnabledExpandedSearchRadiusBanner &&
    (Number(urlParams?.searchRadius) || 0) > (Number(defaultSearchRadius) || 0);

  return (
    showExpandedSearchRadiusBanner && (
      <Alert
        css={{
          marginBottom: '$md',
          '@screen < $md': {
            marginLeft: '$md',
            width: '94%',
          },
        }}
        data-testid="expanded-search-radius-banner"
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        title={expandedSearchRadiusDescription?.title}
        variant="info"
      >
        {expandedSearchRadiusDescription?.message
          ?.replace('{{count}}', providerCountPreference)
          ?.replace('{{miles}}', urlParams?.searchRadius)}
      </Alert>
    )
  );
};
