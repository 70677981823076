/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useLagoon } from '../../hooks/useLagoon';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getCoverageTypes,
  getCurrentMember,
  getMemberCoverageTypeSitusState,
  getOnlineRetailers,
} from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { CardHeader } from '../CardHeader/CardHeader';
import { Chip } from '../Chip';
import { Constants } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { cardHeaderNullPageStyle } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { mappingRollUpCodeToCategory } from '../RecentActivity/helper';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import { isCnsMember } from '../Utils/memberUtils/memberUtils';
import { replaceSpaceToUpperCase } from '../Utils/replaceString';
import { CommonlySearchedDental } from './CommonlySearchedDental';

export const ChipContainer = styled(Flex, {
  gap: '8px',
  '@screen < $md': {
    gap: '12px',
  },
});

export interface CommonSearch {
  id: string;
  category?: string;
  icon: string;
  key: string;
  score?: string;
  psxKeyword: string;
  translatedPsxKeyword: string;
  searchType: string;
  dentalTerm: string;
  visionTerm: string;
  bhTerm: string;
  path?: string;
  embeddedUrl?: string;
  specialitySet?: string;
  DNT_dentalTerm?: string;
  DNT_visionTerm?: string;
  coverageType?: string | undefined;
}

export type Props = {
  selectedChipValue?: string;
  onCommonSearchChipClicked?: () => void;
};

const { ALL, BEHAVIORAL_HEALTH, DENTAL, VISION } = Constants.CHIPS_CATEGORIES;

const createVision = (
  visionTerm: string,
  path: string,
  lat: string,
  long: string,
  baseUrl: string,
  currentZipcode: string
): URL => {
  let visionUrl: URL;
  if (path.includes('rp_zipcode')) {
    visionUrl = new URL(
      baseUrl + path?.replace('rp_zipcode', currentZipcode || '')
    );
    visionUrl.searchParams.set('lat', lat);
    visionUrl.searchParams.set('long', long);
  } else {
    visionUrl = new URL(baseUrl + path);
  }

  return visionUrl;
};

const updateStoresAndNavigate = ({
  item,
  navigate,
  chipStore,
  setAnalyticsState,
  setPCPSearchState,
  setTypeaheadSearchStore,
  setSearchStore,
}) => {
  setAnalyticsState({
    originLinkNameForAnalytics: item?.psxKeyword,
  });
  const pcpStoreValues = {
    choosePCP: false,
  };
  setPCPSearchState(pcpStoreValues);
  const chipStoreValues = {
    chipValue: mappingRollUpCodeToCategory(item?.pesKeyword),
    coverageType: item?.coverageType,
  };
  chipStore.setChipValue(chipStoreValues.chipValue);
  chipStore.setCoverageType(chipStoreValues.coverageType);
  const typeaheadStoreValues = {
    search: item?.psxKeyword,
    searchType: item?.searchType?.toLowerCase(),
    pesKeyword: item?.pesKeyword,
    providerType: item?.providerType ?? '',
    includeSpecialityRollupCodes: item?.pesKeyword,
    combinedIncludeSpecialityRollupCodes: item?.pesKeyword,
  };
  setTypeaheadSearchStore({
    ...typeaheadStoreValues,
    category: item?.category,
    resultType: Constants.RESULT_SECTION.ALL,
    keyword: item?.psxKeyword || item?.searchTerm,
  });
  const searchStoreValues = {
    onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
  };
  setSearchStore(searchStoreValues);
  const urlParams = convertObjectToUrlParams(chipStore, {
    ...chipStoreValues,
    ...pcpStoreValues,
    ...typeaheadStoreValues,
    choosePCP: false,
    chipValue: mappingRollUpCodeToCategory(item?.pesKeyword),
    originLinkNameForAnalytics: item?.psxKeyword,
    resultType: Constants.RESULT_SECTION.ALL,
    coverageType: item?.coverageType,
    keyword: item?.psxKeyword || item?.searchTerm,
    onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
    includeSpecialityRollupCodes: item?.pesKeyword,
  });
  navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
};

export const CommonlySearchedCard = ({
  selectedChipValue = ALL,
  onCommonSearchChipClicked,
}: Props) => {
  const { t } = useTranslation();

  const { longitude, latitude } = getGeoLocationFromStorage();

  const { ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;
  const currentZipcode = storage.local.get(ZIPCODE);

  const baseUrlLagoon = useLagoon(
    Constants.LAGOON_TABLE.VISION_AND_DENTAL_REDIRECTION_BASEURL
  )();
  const baseUrl = baseUrlLagoon?.[0]?.baseUrl;

  const lagoonTableMapping = {
    [BEHAVIORAL_HEALTH]: Constants.LAGOON_TABLE.COMMONLY_SEARCHED.BH_NULL_PAGE,
    [VISION]: Constants.LAGOON_TABLE.COMMONLY_SEARCHED.VISION_NULL_PAGE,
    [DENTAL]: Constants.LAGOON_TABLE.COMMONLY_SEARCHED.DENTAL_NULL_PAGE,
  };

  const lagoonTable = () =>
    lagoonTableMapping[selectedChipValue] ||
    Constants.LAGOON_TABLE.COMMONLY_SEARCHED[selectedChipValue];

  const commonSearchesData: CommonSearch[] =
    lagoonTable() && useLagoon(lagoonTable())();
  const { navigate } = useRouter();

  const { setTypeaheadSearchStore, keyword = '' } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      keyword: state.typeaheadSearchStore.keyword,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const chipStore = useChipStore(
    useShallow((state: ChipState) => ({
      setChipValue: state.setChipValue,
      setCoverageType: state.setCoverageType,
    }))
  );
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      setSearchStore: state.setSearchStore,
    }))
  );

  const { COMMONLY_SEARCHED = '' } =
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[selectedChipValue];
  const featureFlags = useLagoon('feature-flags')();
  const showCommonlySearched = getFeatureFlag(featureFlags, COMMONLY_SEARCHED);
  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );
  const currentMember = getCurrentMember();
  const memberCoverages: [] = getCoverageTypes(currentMember);
  const isCns = enableCns && isCnsMember(currentMember?.lineOfBusiness);
  const getUpdatedToken = (item) => {
    adobeLinkTrackEvent({
      name: item?.psxKeyword,
      location: 'body:browse for care',
      type: 'internal',
    });
    const hasKeyWorkIncluded = [item?.psxKeyword || item?.searchTerm].includes(
      keyword
    );

    if (hasKeyWorkIncluded) {
      return;
    }

    onCommonSearchChipClicked?.();

    updateStoresAndNavigate({
      item,
      navigate,
      chipStore,
      setAnalyticsState,
      setPCPSearchState,
      setTypeaheadSearchStore,
      setSearchStore,
    });
  };
  const getExternalUrlAndOpen = (item) => {
    adobeLinkTrackEvent({
      name: item?.psxKeyword,
      location: 'body:browse for care',
      type: 'external',
    });
    const situsState: string = getMemberCoverageTypeSitusState(currentMember);
    const externalUrl =
      situsState === Constants.MEMBER_COVERAGE_TYPE_SITUSSTATE
        ? Constants.CNS_VISION_URL.ARIZONA
        : Constants.CNS_VISION_URL.DEFAULT;
    window?.open(externalUrl, '_blank');
  };
  const getVisionCare = () =>
    commonSearchesData?.map((item) => {
      const { visionTerm, id, icon, DNT_visionTerm, psxKeyword } = item;
      const { path = '' } = item;
      const visionUrl: URL = createVision(
        visionTerm,
        path,
        latitude,
        longitude,
        baseUrl,
        currentZipcode
      );
      return visionTerm ? (
        <Chip
          adobeChipName={DNT_visionTerm}
          data-auto-testid={`vision-searched-${replaceSpaceToUpperCase(
            visionTerm
          )}`}
          data-testid={`vision-searched-${replaceSpaceToUpperCase(visionTerm)}`}
          icon={icon}
          isStandardAnchor={false}
          key={`vision-search-${id}`}
          text={visionTerm}
          url={visionUrl.toString()}
        />
      ) : (
        <Chip
          adobeChipName={DNT_visionTerm}
          data-auto-testid={`vision-searched-${replaceSpaceToUpperCase(
            psxKeyword
          )}`}
          data-testid={`vision-searched-${replaceSpaceToUpperCase(psxKeyword)}`}
          icon={icon}
          key={`vision-search-${id}`}
          onClick={() => getUpdatedToken(item)}
          text={psxKeyword}
        />
      );
    });
  const getBrowseForCare = (chipValue) => {
    switch (chipValue) {
      case ALL:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              const {
                psxKeyword,
                translatedPsxKeyword,
                id,
                icon,
                coverageType = '',
              } = item;
              const isCNSOptometrist =
                isCns &&
                psxKeyword === Constants.ALL_PAGE_BROWSECARE_PSXKEYWORD;
              // The logic of whether to show or hide the chip based on coverage type is applicable for all lines of business (LOBs).
              const toShowChip = (memberCoverages as string[])?.includes(
                coverageType
              );
              return (
                <Chip
                  data-auto-testid={`commonly-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  data-testid={`commonly-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  extraIcon={isCNSOptometrist}
                  hide={!toShowChip}
                  icon={icon}
                  key={`common-search-${id}`}
                  onClick={() => {
                    isCNSOptometrist
                      ? getExternalUrlAndOpen(item)
                      : getUpdatedToken(item);
                  }}
                  text={translatedPsxKeyword}
                />
              );
            })}
          </ChipContainer>
        );
      case DENTAL:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              let { path } = item;
              const { dentalTerm, id, icon, DNT_dentalTerm, psxKeyword } = item;
              path = path?.replace('rp_zipcode', currentZipcode || '');
              const url = new URL(`${baseUrl}${path}`);
              url.searchParams.set('long', longitude);
              url.searchParams.set('lat', latitude);
              return (
                <CommonlySearchedDental
                  DNT_dentalTerm={DNT_dentalTerm}
                  dentalTerm={dentalTerm}
                  icon={icon}
                  id={id}
                  item={item}
                  key={`common-dental-${id}`}
                  psxKeyword={psxKeyword}
                  updatedToken={() => getUpdatedToken(item)}
                  url={url.toString()}
                />
              );
            })}
          </ChipContainer>
        );
      case VISION:
        return <ChipContainer>{getVisionCare()}</ChipContainer>;

      case BEHAVIORAL_HEALTH:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              const { psxKeyword, id, icon } = item;
              return (
                <Chip
                  data-auto-testid={`bh-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  data-testid={`bh-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  icon={icon}
                  key={`bh-search-${id}`}
                  onClick={() => getUpdatedToken(item)}
                  text={psxKeyword}
                />
              );
            })}
          </ChipContainer>
        );
      default:
        return null;
    }
  };

  return showCommonlySearched && commonSearchesData ? (
    <ContainerForAllSectionsStyled height="auto">
      <ContentWrapper>
        <CardHeader
          css={cardHeaderNullPageStyle}
          data-auto-testid="commonly-searched-card-heading"
          data-testid="commonly-searched-card-heading"
          offset={3}
        >
          {t('Browse for care')}
        </CardHeader>
        {getBrowseForCare(selectedChipValue)}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  ) : null;
};
