import { phoneOnly } from "../../common/ConstantsStyles";
import { useMediaQuery } from "@abyss/web/hooks/useMediaQuery";
import { ConstantsLagoon } from "../../common/ConstantsLagoon";
import { getFeatureFlag } from "../../common/Utils/getFeatureFlag";
import { useLagoon } from "../useLagoon/useLagoon";

export const useSnackcardViewAllCount = (count = 0) => {

  const mobileScreen = useMediaQuery(phoneOnly);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const isExpandViewAllEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EXPANDED_SEARCH_RADIUS
  );

  let viewAllCount = '';
  let styleProps = {};
  let css = {};
  if (isExpandViewAllEnabled) {
    viewAllCount = count > 200 ? '(200+)' : `(${count?.toString()})`;
    styleProps = {
      size: mobileScreen ? "$sm" : "$lg",
      iconSize: mobileScreen ? "$sm" : "$md"
    };
    css = {
      'abyss-link-root': {
        marginLeft: mobileScreen ? 0 : '40px',
        lineHeight: mobileScreen ? '18px' : '24px',
      },
    };

    if (count > 0 && mobileScreen){
      css = {
        'abyss-link-root': {
          '& .abyss-insert-element-after': {
            display: 'none'
          }
        }
      }
    }
  }

  return {
    viewAllCount,
    isExpandViewAllEnabled,
    styleProps,
    css
  };
};