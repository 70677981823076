import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

import {
  Constants,
  FilterCollectionModel,
  FilterOptionsType,
  LOB,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { phoneOnly } from '../../common/ConstantsStyles';
import { getFeatureFlag } from '../../common/Utils';
import { isMnrCosmosMember } from '../../common/Utils/memberUtils/memberUtils';
import { suppressFiltersByFeatureAndPolicy } from '../../common/Utils/suppressFiltersByFeatureAndPolicy';
import {
  returnInclusionFlag,
  returnSuppressFlag,
} from '../../utils/featureSuppress';
import { portalShouldShowOnlineScheduling } from '../../utils/portalConfig.utils';
import { portalShouldShowVirtualVisits } from '../../utils/portalConfig.utils';
import { showECP } from '../../utils/providerDetails.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { useCoverageType } from '../useCoverageType';
import { useCurrentMemberIsPreEffective } from '../useCurrentMemberIsPreEffective';
import { useLagoon } from '../useLagoon';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL, VISION, DENTAL } = ReverseCoverageTypesCodes;

export const useProviderFilters = () => {
  const { t } = useTranslation();
  const { lineOfBusiness, population, demographics } = getCurrentMember();
  const isPreEffective = useCurrentMemberIsPreEffective();
  const isMnr = isMnrCosmosMember(lineOfBusiness, population);
  const coverageType = useCoverageType();
  const isMobile = useMediaQuery(phoneOnly);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const isACOFilterDisabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_ACO_FILTER
  );

  const isDistanceSlider: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_DISTANCE_FILTER_SLIDER
  );
  const toShowDistanceSlider = !isMobile && isDistanceSlider;
  const isPreferredFacilityDisabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PREFERRED_FACILITY_FILTER
  );
  const isVirtualCareFilterEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_VIRTUAL_CARE_BH_FILTER
  );
  const upcomingAvailability = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY
  );
  const upcomingAvailabilityForMedical = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY_FOR_MEDICAL
  );
  const upcomingAvailabilityForBh = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY_FOR_BH
  );
  const isBehavioralFacilityFilterEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_FACILITY_PROGRAMS_FILTER
  );

  const suppressVirtualCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_EMPIRE
  );

  const suppressDocAsap = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.SUPPRESS_DOC_ASAP
  );

  const includeDocAsap =
    lineOfBusiness === LOB.CNS &&
    returnInclusionFlag(
      ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
    );

  const upComingAvailabilityValidCoverages: string[] = [];
  if (upcomingAvailabilityForMedical && !upcomingAvailability) {
    upComingAvailabilityValidCoverages.push(MEDICAL);
  }
  if (upcomingAvailabilityForBh) {
    upComingAvailabilityValidCoverages.push(BEHAVIORAL_HEALTH);
  }

  const showOnlineScheduling = portalShouldShowOnlineScheduling();
  const portalShowVirtualVisits = portalShouldShowVirtualVisits();
  const ecpEnabled = showECP(lineOfBusiness, demographics?.address?.[0]?.state);

  const filters = [
    {
      label: 'ACO',
      title: t('CONTENT_FILTERS.ACO'),
      model: FilterCollectionModel.ACONAME,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 14,
        [BEHAVIORAL_HEALTH]: 14,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !!isACOFilterDisabled,
    },
    {
      label: t('CONTENT_FILTERS.DISTANCE'),
      title: toShowDistanceSlider
        ? `${t('CONTENT_FILTERS.DISTANCE')} (miles)`
        : t('CONTENT_FILTERS.DISTANCE'),
      model: FilterCollectionModel.DISTANCE,
      type: toShowDistanceSlider
        ? FilterOptionsType.SLIDER
        : FilterOptionsType.RADIO,
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 4,
        [BEHAVIORAL_HEALTH]: 4,
        [VISION]: 1,
        [DENTAL]: 1,
      },
      chipFiltersOrder: {
        [MEDICAL]: isMnr ? 2 : 1,
        [BEHAVIORAL_HEALTH]: 1,
        [VISION]: 1,
        [DENTAL]: 1,
      },
      options: [1, 5, 10, 25, 50, 100, 120].map((value, index) => ({
        label: `${value} ${t('CONTENT_FILTERS.MILE')}${value > 1 ? 's' : ''}`,
        value,
        c_value: index,
        dnt_label: `${value} miles`,
      })),
    },
    {
      label: t('CONTENT_FILTERS.VIRTUAL_CARE'),
      title: t('CONTENT_FILTERS.VIRTUAL_CARE'),
      model: FilterCollectionModel.VIRTUAL_CARE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 2,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [
        {
          label: t('FILTER_OPTIONS.VirtualCare.Y'),
          dnt_label: t('FILTER_OPTIONS.VirtualCare.Y'),
          value: true,
        },
      ],
      isDisabled:
        !portalShowVirtualVisits ||
        !isVirtualCareFilterEnabled ||
        suppressVirtualCare,
    },
    {
      label: t('CONTENT_FILTERS.PATIENT_REVIEWS'),
      model: FilterCollectionModel.PATIENT_REVIEWS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 5,
        [BEHAVIORAL_HEALTH]: 3,
        [VISION]: 3,
        [DENTAL]: 2,
      },
      chipFiltersOrder: {
        [MEDICAL]: 2,
        [BEHAVIORAL_HEALTH]: 2,
        [VISION]: 2,
        [DENTAL]: 2,
      },
      options: [
        {
          label: t('CONTENT_FILTERS.5_STARS'),
          value: '5',
          dnt_label: '5 Stars',
        },
        ...['4', '3', '2'].map((value) => ({
          label: `${value} ${t('CONTENT_FILTERS.STARS_AND_UP')}`,
          value,
          dnt_label: `${value} Stars and up`,
        })),
        {
          label: t('CONTENT_FILTERS.1_STAR'),
          value: '1',
          dnt_label: '1 Star and up',
        },
      ],
    },
    {
      label: t('CONTENT_FILTERS.SPECIALTY'),
      model: FilterCollectionModel.SPECIALTY_CODE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 6,
        [BEHAVIORAL_HEALTH]: 6,
        [VISION]: 2,
        [DENTAL]: 3,
      },
      chipFiltersOrder: {
        [MEDICAL]: 6,
        [BEHAVIORAL_HEALTH]: 6,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.AREAS_OF_EXPERTISE'),
      model: FilterCollectionModel.AREA_OF_EXPERTISE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 8,
        [BEHAVIORAL_HEALTH]: 8,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.FACILITY_TYPE'),
      model: FilterCollectionModel.FACILITY_TYPE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 6,
        [VISION]: 2,
      },
      chipFiltersOrder: { [MEDICAL]: 5, [VISION]: 2 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PREFERRED_FACILITY'),
      model: FilterCollectionModel.PREFERRED_FACILITY,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 5,
        [BEHAVIORAL_HEALTH]: 5,
        [VISION]: 1,
      },
      chipFiltersOrder: { [MEDICAL]: 4, [BEHAVIORAL_HEALTH]: 4, [VISION]: 2 },
      options: [],
      isDisabled: !isPreferredFacilityDisabled,
    },
    {
      label: t('CONTENT_FILTERS.EVENING_WEEKENDS'),
      model: FilterCollectionModel.EVENING_AND_WEEKEND,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 11,
        [BEHAVIORAL_HEALTH]: 11,
        [VISION]: 5,
        [DENTAL]: 5,
      },
      chipFiltersOrder: {
        [MEDICAL]: 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HEALTH_SUPPLIES_OFFERED'),
      model: FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 12,
        [BEHAVIORAL_HEALTH]: 12,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 7, [BEHAVIORAL_HEALTH]: 7, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ACCESSIBILITY'),
      model: FilterCollectionModel.ACCESSIBILITY_CODE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 13,
        [BEHAVIORAL_HEALTH]: 13,
        [VISION]: 6,
        [DENTAL]: 6,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.LANGUAGE'),
      model: FilterCollectionModel.LANGUAGE_CODE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 16,
        [BEHAVIORAL_HEALTH]: 15,
        [VISION]: 8,
        [DENTAL]: 8,
      },
      chipFiltersOrder: {
        [MEDICAL]: 11,
        [BEHAVIORAL_HEALTH]: 11,
        [VISION]: 4,
        [DENTAL]: 4,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.RACE'),
      model: FilterCollectionModel.RACE_CODE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 17,
        [BEHAVIORAL_HEALTH]: 17,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.GENDER'),
      model: FilterCollectionModel.GENDER_CODE,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 19,
        [BEHAVIORAL_HEALTH]: 19,
        [VISION]: 7,
        [DENTAL]: 7,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ETHNICITY'),
      model: FilterCollectionModel.ETHNICITY_CODE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 18,
        [BEHAVIORAL_HEALTH]: 18,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROVIDER_GROUP'),
      model: FilterCollectionModel.MEDICAL,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 18,
        [BEHAVIORAL_HEALTH]: 18,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 3, [BEHAVIORAL_HEALTH]: 3, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS'),
      model: FilterCollectionModel.HOSPITAL_AFFILIATIONS,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 14,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ESSENTIAL_COMMUNITY_PROVIDER'),
      model: FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 15,
        [BEHAVIORAL_HEALTH]: 11,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !ecpEnabled,
      isSortingOptionsDisabled: true,
    },
    {
      label: t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS'),
      model: FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: isMnr ? [PROVIDER, PROVIDER_GROUPS] : [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 20,
        [BEHAVIORAL_HEALTH]: 20,
        [VISION]: 9,
        [DENTAL]: 9,
      },
      chipFiltersOrder: {
        [MEDICAL]: isMnr ? 1 : 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        DENTAL: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROGRAMS'),
      model: FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 7,
        [BEHAVIORAL_HEALTH]: 7,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 8, [BEHAVIORAL_HEALTH]: 8, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.SERVICES_PROVIDED'),
      model: FilterCollectionModel.SERVICES_OFFERED,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 4,
        [DENTAL]: 4,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 3,
        [DENTAL]: 3,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.UPCOMING_AVAILABILITY'),
      model: FilterCollectionModel.UPCOMING_AVAILABILITY,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 9,
        [BEHAVIORAL_HEALTH]: 8,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 4, [BEHAVIORAL_HEALTH]: 3, [VISION]: 0 },
      options: [2, 7, 14, 30].map((value) => ({
        label: `Next ${value} days`,
        value,
        dnt_label: `Next ${value} days`,
      })),
      isSortingOptionsDisabled: true,
      isDisabled:
        !showOnlineScheduling ||
        isPreEffective ||
        upComingAvailabilityValidCoverages.indexOf(coverageType as any) ===
          -1 ||
        (lineOfBusiness === LOB.CNS ? !includeDocAsap : suppressDocAsap),
    },
    {
      label: t('CONTENT_FILTERS.AGE_RANGE'),
      model: FilterCollectionModel.AGE_RANGE,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 4,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 1,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !isBehavioralFacilityFilterEnabled,
      isSortingOptionsDisabled: true,
    },
    {
      label: t('CONTENT_FILTERS.PROGRAM'),
      model: FilterCollectionModel.PROGRAM,
      type: FilterOptionsType.CHECK_BOX,
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 4,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 1,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !isBehavioralFacilityFilterEnabled,
      isSortingOptionsDisabled: true,
    },
    {
      label: t('CONTENT_FILTERS.PROGRAM_CATEGORY'),
      model: FilterCollectionModel.PROGRAM_CATEGORY,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 4,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !isBehavioralFacilityFilterEnabled,
      isSortingOptionsDisabled: true,
    },
  ];

  suppressFiltersByFeatureAndPolicy(filters);

  return filters;
};
