import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon/useLagoon';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../models/ProviderDetails';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import {
  filterNotEmptyHoursOfOperations,
  getUncommonElements,
} from '../../../utils/providerDetails.utils';
import { capitalizeAllFirstCharacters } from '../../../utils/providerNames.utils';
import { getCurrentMember, isCNSMember } from '../../../utils/user.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import { AllOtherInfos } from './AllOtherInfos/AllOtherInfos';
import { CollapsePhones } from './CollapsePhones';
import { InfoProviderContent } from './InfoProviderContent';
import { OpsHours } from './OpsHours';
import { PhoneNumberList } from './PhoneNumberList';

type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

export const InfoProvider = ({ providerDetails, selectedLocation }: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );
  const { lineOfBusiness } = getCurrentMember();
  const isCns = isCNSMember(lineOfBusiness);
  const isVisionCare = coverageType === ReverseCoverageTypesCodes.VISION;
  const isMedical = coverageType === ReverseCoverageTypesCodes.MEDICAL;
  const isBehavioralHealth =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
  const { phones, tty, dbaName } = selectedLocation || {};

  const data = useLagoon('ui-messaging')();
  const featureFlags = useLagoon('feature-flags')();
  const showAcceptsMedicaid = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_ACCEPTS_MEDICAID
  );

  const popoverContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.ACCEPTS_MEDICAID_POPOVER_CONTENT,
  })?.message;

  let icon: string;
  let acceptingPatientInfo: string;

  if (selectedLocation?.acceptingNewPatients == null) {
    icon = 'do_disturb_alt';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NO_INFO_AVAILABLE';
  } else if (selectedLocation?.acceptingNewPatients) {
    icon = 'done';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.ACCEPTING_PATIENTS';
  } else {
    icon = 'close';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NOT_ACCEPTING_PATIENTS';
  }

  // utils calls
  const notEmptyHoursOfOperation =
    filterNotEmptyHoursOfOperations(selectedLocation);

  const availableDays = notEmptyHoursOfOperation?.map((days) =>
    days.day.toLowerCase()
  );

  const notAvailableDays = getUncommonElements(
    Constants.ALL_DAYS,
    availableDays
  );

  const showDBAName =
    isCns &&
    ((providerDetails.providerType !== ProviderType.PRACTITIONER &&
      (isMedical || isBehavioralHealth)) ||
      (isVisionCare &&
        providerDetails.providerType === ProviderType.ORGANIZATION));
  const showInfoProviderContent =
    providerDetails.providerType !== ProviderType.ORGANIZATION || isVisionCare;
  return (
    <React.Fragment>
      {showAcceptsMedicaid && selectedLocation?.isAcceptingMedicaid && (
        <InfoProviderContent
          icon={'done'}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCEPTS_MEDICAID')}
          popoverContent={popoverContent}
          popoverTestId="accepts-medicaid-info-icon-popover"
          popoverTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCEPTS_MEDICAID')}
          showPopover
          showSubTitle={false}
        />
      )}

      {showDBAName ? (
        <InfoProviderContent
          icon="videocam"
          infoSubtext={capitalizeAllFirstCharacters(dbaName?.toLowerCase())}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.DBA')}
          showSubTitle
        />
      ) : null}
      {showInfoProviderContent ? (
        <InfoProviderContent
          icon={icon}
          infoTitle={t(acceptingPatientInfo)}
          showSubTitle={false}
        />
      ) : null}
      {!(notAvailableDays.length >= 7) && (
        <OpsHours selectedLocation={selectedLocation} />
      )}

      {phoneScreen ? (
        <CollapsePhones
          collapseHeader={t('PROVIDER_LOCATIONS_DETAILS.SHOW_MORE_PHONE')}
          phoneNumbers={phones}
          providerDetails={providerDetails as ProviderDetails}
          tty={tty}
        />
      ) : (
        <PhoneNumberList
          phoneNumbers={phones}
          providerDetails={providerDetails as ProviderDetails}
          tty={tty}
        />
      )}
      <AllOtherInfos
        providerDetails={providerDetails}
        selectedLocation={selectedLocation}
      />
    </React.Fragment>
  );
};
