export const ConstantsLagoon = {
  CONTENT_FILTERS: {
    INFO_MODAL_CONTENT: 'RESULT_SORT_INFO_MODAL_CONTENT',
    PREMIUM_CARE_PHYSICIAN_CONTENT: 'PREMIUM_CARE_PHYSICIAN_CONTENT',
    PREMIUM_CARE_POPOVER_URL: 'PREMIUM_CARE_POPOVER_URL',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    BEST_MATCH_MEDICAL_PROVIDER: 'BEST_MATCH_MEDICAL_PROVIDER',
    BEST_MATCH_MEDICAL_PROVIDER_GROUP: 'BEST_MATCH_MEDICAL_PROVIDER_GROUP',
    BEST_MATCH_MEDICAL_FACILITY: 'BEST_MATCH_MEDICAL_FACILITY',
    BEST_MATCH_BH_PROVIDER: 'BEST_MATCH_BH_PROVIDER',
    BEST_MATCH_BH_PROVIDER_GROUP: 'BEST_MATCH_BH_PROVIDER_GROUP',
    BEST_MATCH_BH_FACILITY: 'BEST_MATCH_BH_FACILITY',
    BEST_MATCH_DENTAL_PROVIDER: 'BEST_MATCH_DENTAL_PROVIDER',
    BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER:
      'BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER',
    BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY:
      'BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY',
    BEST_MATCH_POPOVER_CONTENT: 'BEST_MATCH_POPOVER_CONTENT',
    COST_TAB_IN_NETWORK_TEXT: 'COST_TAB_IN_NETWORK_TEXT',
    COST_TAB_COST_ESTIMATES_TEXT: 'COST_TAB_COST_ESTIMATES_TEXT',
    COST_TAB_COST_ESTIMATES_HEADING: 'COST_TAB_COST_ESTIMATES_HEADING',
    COST_TAB_COST_ESTIMATES_NOTIFICATION:
      'COST_TAB_COST_ESTIMATES_NOTIFICATION',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT',
    COST_TAB_IN_NETWORK_HEADING: 'COST_TAB_IN_NETWORK_HEADING',
    CARE_TYPE_TITLE: 'CARE_TYPE_TITLE',
    OON_MESSAGE: 'OON_MESSAGE',
    SELECT_CARE_TYPE_TITLE: 'SELECT_CARE_TYPE_TITLE',
    PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT:
      'PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT',
    PHYSICIAN_DIRECTORY_LOCATION_CONTENT:
      'PHYSICIAN_DIRECTORY_LOCATION_CONTENT',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_ERROR: 'PHYSICIAN_DIRECTORY_SEARCH_ERROR',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_NAME_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_NAME_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_SPECIALITY_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_SPECIALITY_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_OPTIONS:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_OPTIONS',
    PHYSICIAN_DIRECTORY_HOSPITAL_COPY_CONTENT:
      'PHYSICIAN_DIRECTORY_HOSPITAL_COPY_CONTENT',
    PHYSICIAN_DIRECTORY_PROVIDERGROUP_COPY_CONTENT:
      'PHYSICIAN_DIRECTORY_PROVIDERGROUP_COPY_CONTENT',
    PHYSICIAN_DIRECTORY_HOSPITAL_NO_PROVIDER:
      'PHYSICIAN_DIRECTORY_HOSPITAL_NO_PROVIDER',
    PHYSICIAN_DIRECTORY_PROVIDERGROUP_NO_PROVIDER:
      'PHYSICIAN_DIRECTORY_PROVIDERGROUP_NO_PROVIDER',
    NONTIER1_LOCATION_LABEL: 'NONTIER1_LOCATION_LABEL',
    ACCEPTS_MEDICAID_POPOVER_CONTENT: 'ACCEPTS_MEDICAID_POPOVER_CONTENT',
    PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT:
      'PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT',
    DETAILS_WIDGET_NO_DETAILS_TITLE: 'DETAILS_WIDGET_NO_DETAILS_TITLE',
    DETAILS_WIDGET_NO_DETAILS_SUB_TITLE: 'DETAILS_WIDGET_NO_DETAILS_SUB_TITLE',
    DETAILS_WIDGET_NO_DETAILS_INFO: 'DETAILS_WIDGET_NO_DETAILS_INFO',
    UPCOMING_AVAILABILITY_FILTER_DESCRIPTION:
      'UPCOMING_AVAILABILITY_FILTER_DESCRIPTION',
  },
  FEATURE_FLAGS: {
    AUTOCOMPLETE_PARALLEL_SEARCHFLOW: 'AUTOCOMPLETE_PARALLEL_SEARCHFLOW',
    BEHAVIORAL_FACILITY_PROGRAMS_FILTER: 'BEHAVIORAL_FACILITY_PROGRAMS_FILTER',
    DEMO_FLAG: 'DEMO',
    REQUEST_RECIPROCITY_ID: 'REQUEST_RECIPROCITY_ID',
    EDUCATIONAL_CONTENT_ENABLED: 'EDUCATIONAL_CONTENT_ENABLED',
    COST_ENABLED: 'COST_ENABLED',
    BH_FACILITIES_PROGRAMIDS_SECTIONS: 'BH_FACILITIES_PROGRAMIDS_SECTIONS',
    COST_TAB_ENABLED: 'COST_TAB',
    ENABLE_CEX_GET_FULL_COST_ESTIMATE: 'ENABLE_CEX_GET_FULL_COST_ESTIMATE',
    ENABLE_PRE_PROD: 'ENABLE_PRE_PROD_ENV',
    PRINT_SHARE_DIRECTORY: 'PRINT_SHARE_DIRECTORY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    SHARE_RECIPIENT_DISABLE: 'SHARE_RECIPIENT_DISABLE',
    THINGS_TO_KNOW_DECEMBER_RLS: 'THINGS_TO_KNOW_DECEMBER_RLS',
    ENABLE_SEARCH_LOCATION: 'ENABLE_SEARCH_LOCATION',
    AUTOCOMPLETE_CAPTURE_RESULTS: 'AUTOCOMPLETE_CAPTURE_RESULTS',
    UPCOMING_AVAILABILITY: 'UPCOMING_AVAILABILITY',
    ENABLE_COUNTY_SEARCH_ALERT: 'ENABLE_COUNTY_SEARCH_ALERT',
    SHOW_MAP_COLLAPSE: 'SHOW_MAP_COLLAPSE',
    REFERRAL_REQUIRED_BANNER: 'REFERRAL_REQUIRED_BANNER',
    VIRTUAL_CARE_INDICATOR_ENABLED: 'VIRTUAL_CARE_INDICATOR_ENABLED',
    CARE_CATEGORY_CHIPS_TO_TABS_ENABLED: 'CARE_CATEGORY_CHIPS_TO_TABS_ENABLED',
    VISION_CHIP_ENABLED: 'VISION_CHIP_ENABLED',
    DENTAL_CHIP_ENABLED: 'DENTAL_CHIP_ENABLED',
    USE_MEDICAL_NEW_ROLLUP_CODES: 'USE_MEDICAL_NEW_ROLLUP_CODES',
    USE_BH_NEW_ROLLUP_CODES: 'USE_BH_NEW_ROLLUP_CODES',
    USE_DENTAL_NEW_ROLLUP_CODES: 'USE_DENTAL_NEW_ROLLUP_CODES',
    USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH: 'USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH',
    USE_VISION_NEW_ROLLUP_CODES: 'USE_VISION_NEW_ROLLUP_CODES',
    DISABLE_ACO_FILTER: 'DISABLE_ACO_FILTER',
    DISABLE_PREFERRED_FACILITY_FILTER: 'DISABLE_PREFERRED_FACILITY_FILTER',
    ENABLE_VIRTUAL_CARE_BH_FILTER: 'ENABLE_VIRTUAL_CARE_BH_FILTER',
    VISION_WEEKEND_EVENINGS_FILTER_ENABLED:
      'VISION_WEEKEND_EVENINGS_FILTER_ENABLED',
    VISION_RECENT_VISIT: 'VISION_RECENT_VISIT',
    EAP_CODE: 'EAP_CODE',
    TIERED_BENEFITS_ENABLED: 'TIERED_BENEFITS_ENABLED',
    FEATURE_SUPPRESSION: 'FEATURE_SUPPRESSION',
    DENTAL_INTEGRATED_VIEW: 'DENTAL_INTEGRATED_VIEW',
    PREMIUM_CARE: 'PREMIUM_CARE',
    CUSTOM_MESSAGE_NOTIFICATION: 'CUSTOM_MESSAGE_NOTIFICATION',
    ENABLE_LLM: 'ENABLE_LLM',
    TIER_ONE_FILTER_COMPARE: 'TIER_ONE_FILTER_COMPARE',
    PATIENT_REVIEWS_DYNAMIC_HEADER: 'PATIENT_REVIEWS_DYNAMIC_HEADER',
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    TRACK_BACK_BUTTON_ENABLED: 'TRACK_BACK_BUTTON_ENABLED',
    ENABLE_INCLUDE_SPEC_CODE_CHANGES: 'ENABLE_INCLUDE_SPEC_CODE_CHANGES',
    ENABLE_PCP_ALERT_BANNER: 'ENABLE_PCP_ALERT_BANNER',
    ENABLE_EXPRESS_ACCESS_PROVIDER_FILTER:
      'ENABLE_EXPRESS_ACCESS_PROVIDER_FILTER',
    ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER:
      'ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER',
    INCLUDE_PROVIDER_GROUP_NAME: 'INCLUDE_PROVIDER_GROUP_NAME',
    GET_PROVIDER_RATINGS: 'PROVIDER_RATINGS',
    GREEN_DIAMOND_IDENTIFICATION: 'GREEN_DIAMOND_IDENTIFICATION',
    CLEANUP_PROVIDER_CALLS: 'CLEANUP_PROVIDER_CALLS',
    ENABLE_PLAN_SELECTION_V2: 'ENABLE_PLAN_SELECTION_V2',
    BEHAVIORAL_HEALTH_VIRTUAL_CARE: 'BEHAVIORAL_HEALTH_VIRTUAL_CARE',
    USP_TIER1_ENABLE: 'USP_TIER1_ENABLE',
    USP_ENABLE: 'USP_ENABLE',
    UNET_NONTIER1_LOCATION: 'UNET_NONTIER1_LOCATION',
    ENABLE_COST_ESTIMATES_NOTIFICATION: 'ENABLE_COST_ESTIMATES_NOTIFICATION',
    ENABLE_ONPREM_MAPBOX: 'ENABLE_ONPREM_MAPBOX',
    ENABLE_MNR_COSMOS: 'ENABLE_MNR_COSMOS',
    ENABLE_CNS: 'ENABLE_CNS',
    OXFORD_PLN: 'OXFORD_PLN',
    DISABLE_TIMESTAMP: 'DISABLE_TIMESTAMP',
    DISABLE_MEDICAL_TIMESTAMP: 'DISABLE_MEDICAL_TIMESTAMP',
    ENABLE_AVAILABILITY_SECTION: 'ENABLE_AVAILABILITY_SECTION',
    SHOW_ISPREEFFECTIVE_INDICATOR: 'SHOW_ISPREEFFECTIVE_INDICATOR',
    SHOW_ACCEPTS_MEDICAID: 'SHOW_ACCEPTS_MEDICAID',
    DISPLAY_FUTURE_TERMINATION_DATE: 'DISPLAY_FUTURE_TERMINATION_DATE',
    ENABLE_DISTANCE_FILTER_SLIDER: 'ENABLE_DISTANCE_FILTER_SLIDER',
    ENABLE_MAP_LOCATION_PIN_HOVER: 'ENABLE_MAP_LOCATION_PIN_HOVER',
    IS_SUPPRESS_PREEFFECTIVE: 'IS_SUPPRESS_PREEFFECTIVE',
    FUTURE_TERMINATION_DEMO: 'FUTURE_TERMINATION_DEMO',
    SHOW_NETWORK_AFFILIATION: 'SHOW_NETWORK_AFFILIATION',
    MEMBER_INFO_POLICY_FILTER: 'MEMBER_INFO_POLICY_FILTER',
    ENABLE_BH_FACILITIES_PROGRAM_SEARCH: 'ENABLE_BH_FACILITIES_PROGRAM_SEARCH',
    FEATURE_INCLUSION: 'FEATURE_INCLUSION',
    ENABLE_BH_AOE_TYPEAHEAD: 'ENABLE_BH_AOE_TYPEAHEAD',
    SHOW_DEPENDENT: 'SHOW_DEPENDENT',
    SHOW_FUTURE_PCP: 'SHOW_FUTURE_PCP',
    ENABLE_SCHEDULE_OPTION: 'ENABLE_SCHEDULE_OPTION',
    ENABLE_SCHEDULING_FOR_DEPENDENT: 'ENABLE_SCHEDULING_FOR_DEPENDENT',
    ONLINE_SCHEDULING: 'ONLINE_SCHEDULING',
    PSX_CTA_DISPLAY_LOGIC: 'PSX_CTA_DISPLAY_LOGIC',
    ENABLE_FIND_ELIGIBLE_PCP_BANNER: 'ENABLE_FIND_ELIGIBLE_PCP_BANNER',
    ENABLE_L2_CULTURAL_COMPETENCIES: 'ENABLE_L2_CULTURAL_COMPETENCIES',
    ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE:
      'ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE',
    ENABLE_TIER_ONE_MOCK_PROVIDER_IDS: 'ENABLE_TIER_ONE_MOCK_PROVIDER_IDS',
    ENABLE_EXPANDED_SEARCH_RADIUS_BANNER:
      'ENABLE_EXPANDED_SEARCH_RADIUS_BANNER',
    UPCOMING_AVAILABILITY_FOR_MEDICAL: 'UPCOMING_AVAILABILITY_FOR_MEDICAL',
    UPCOMING_AVAILABILITY_FOR_BH: 'UPCOMING_AVAILABILITY_FOR_BH',
    RACE_ETHNICITY: 'RACE_ETHNICITY',
    ENABLE_EXPAND_VIEW_ALL: 'ENABLE_EXPAND_VIEW_ALL',
    ENABLE_EXPANDED_SEARCH_RADIUS: 'ENABLE_EXPANDED_SEARCH_RADIUS',
    ENABLE_NAVIGATE_TO_CORRECT_CATEGORY: 'ENABLE_NAVIGATE_TO_CORRECT_CATEGORY',
  },
  MEDICAL_SPECIALISTS: {
    HOW_TO_GET_REFERRAL_CARD: 'HOW_TO_GET_REFERRAL',
    TIER_ONE_BENEFITS_ONE: 'TIER_ONE_BENEFITS_ONE',
  },
  BEHAVIORAL_HEALTH: {
    TITLE_KEY: 'TITLE',
    CONTENT_KEY: 'CONTENT',
    TALK_TO_NURSE_CARD_INFO: 'TALK_TO_NURSE_CARD_INFO',
  },
  PRIMARY_CARE: {
    PRIMARY_CARE_PROVIDERS: 'PRIMARY_CARE_PROVIDERS',
    PRIMARY_CARE_LOCATIONS: 'PRIMARY_CARE_LOCATIONS',
    PRIMARY_CARE_CLINICS: 'PRIMARY_CARE_CLINICS',
    PRIMARY_CARE_MEDICAL_GROUPS: 'PRIMARY_CARE_MEDICAL_GROUPS',
    TIER_ONE_PROVIDER: 'TIER_ONE_PROVIDER',
    TIERED_BENEFITS: 'TIERED_BENEFITS',
    PCP_NOT_CHOSEN_ELIGIBILITY: 'PCP_NOT_CHOSEN_ELIGIBILITY',
    PCP_NOT_CHOSEN_ACCEPTANCE: 'PCP_NOT_CHOSEN_ACCEPTANCE',
    NO_PCP_MESSAGE: 'NO_PCP_MESSAGE',
    PCP_WILL_BE_ASSIGNED_SUBSCRIBER: 'PCP_WILL_BE_ASSIGNED_SUBSCRIBER',
    PCP_WILL_BE_ASSIGNED_DEPENDENT: 'PCP_WILL_BE_ASSIGNED_DEPENDENT',
  },
  SHARE_THIS_PROVIDER: {
    SHARE_THIS_PROVIDER_MESSAGE: 'SHARE_THIS_PROVIDER_MESSAGE',
  },
  VALID_EMAIL_ADDRESS: {
    VALID_EMAIL_ADDRESS_MESSAGE: 'VALID_EMAIL_ADDRESS_MESSAGE',
  },
  SEARCH_LABEL: 'SEARCH_LABEL',
  LOCATION_LABEL: 'LOCATION_LABEL',
  SUGGESTED_PROVIDERS: {
    SUGGESTED_PROVIDERS_MESSAGE: 'SUGGESTED_PROVIDERS_MESSAGE',
  },
  HEALTHGRADES: {
    MODAL_DISCLAIMER_SHORT: 'HEALTH_GRADES_DISCLAIMER_CONTENT',
    MODAL_DISCLAIMER_FULL: 'HEALTH_GRADES_DISCLAIMER_POPUP',
    DISCLAIMER_IN_PAGE: 'HEALTH_GRADES_IN_PAGE',
    PRINT_VIEW_CONTENT: 'HEALTH_GRADES_PRINT_VIEW_CONTENT',
  },
  DENTAL: {
    TITLE_KEY: 'TITLE',
  },
  VISION: {
    TITLE_KEY: 'TITLE',
    VISION_PAGE_TALK_TO_NURSE_TOGGLE: 'VISION_PAGE_TALK_TO_NURSE_TOGGLE',
  },
  EDUCATIONAL_CONTENT_MODAL: {
    EDUCATIONAL_CONTENT_MODAL_TITLE: 'EDUCATIONAL_CONTENT_MODAL_TITLE',
    EDUCATIONAL_CONTENT_MODAL_DESCRIPTION:
      'EDUCATIONAL_CONTENT_MODAL_DESCRIPTION',
  },
  LANGUAGE_MODAL: {
    LANGUAGE_NOT_AVAILABLE: 'LANGUAGE_NOT_AVAILABLE',
    LANGUAGE_NOT_AVAILABLE_CONTENT: 'LANGUAGE_NOT_AVAILABLE_CONTENT',
  },
  COST_INDICATOR_CONTENT_MODAL: {
    COST_INDICATOR_POPOVER_CONTENT: 'COST_INDICATOR_POPOVER_CONTENT',
  },
  NATIONAL_ANCILLARY_NO_LOCATION_MODAL: 'NATIONAL_ANCILLARY_NO_LOCATION_MODAL',
  ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL:
    'ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL',
  MEMBER_SELECTION_CONFIRMATION_MODAL: {
    MEMBER_SELECTION_MODAL_BODY_CONTENT: 'MEMBER_SELECTION_MODAL_BODY_CONTENT',
    MEMBER_SELECTION_MODAL_BODY_HEADER: 'MEMBER_SELECTION_MODAL_BODY_HEADER',
    MEMBER_SELECTION_MODAL_PCP_CONTENT: 'MEMBER_SELECTION_MODAL_PCP_CONTENT',
    MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT',
    MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT',
  },
  NO_PROVIDERS_FOUND: 'NO_PROVIDERS_FOUND',
  FEATURE_SUPPRESSION_FLAGS: {
    PREMIUM_CARE: 'Premium Care',
    PATIENT_REVIEWS: 'Patient Reviews',
    PREFERRED_FACILITY: 'Preferred Facility',
    DDP: 'DDP',
    PLN: 'PLN',
    FSF: 'FSF',
    EAP: 'EAP',
    CUSTOM_PREFERRED_POLICY: 'Custom Preferred Policy',
    COST_ESTIMATES: 'Cost Estimates',
    COST_ESTIMATES_EMPIRE: 'Cost Estimates Empire',
    VIRTUAL_CARE_EMPIRE: 'Virtual Care Empire',
    SUPPRESS_DOC_ASAP: 'Suppress Doc ASAP',
  },
  FEATURE_INCLUSION_FLAGS: {
    INCLUDE_DOC_ASAP: 'DocASAP',
    INCLUDE_CNS_OH: 'C&S Ohio',
  },
  EAP_AUTHORIZATION_CODE: 'EAP_AUTHORIZATION_CODE',
  PREFERRED_FACILITY: {
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT:
      'DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT',
    PREFERRED_LAB_NETWORK_CONTENT: 'PREFERRED_LAB_NETWORK_CONTENT',
    FREESTANDING_FACILITY_CONTENT: 'FREESTANDING_FACILITY_CONTENT',
    OXFORD_PREFERRED_LAB_CONTENT: 'OXFORD_PREFERRED_LAB_CONTENT',
  },
  PREFERRED_PROVIDER: {
    PREFERRED_PROVIDER_CONTENT: 'PREFERRED_PROVIDER_CONTENT',
  },
  TYPEAHEAD_PARAMS: {
    USER_TEXT_SEARCH_LABEL: 'USER_TEXT_SEARCH_LABEL',
  },
  LEAPFROG: {
    LEAPFROG_DISCLAIMER_CONTENT: 'LEAPFROG_DISCLAIMER_CONTENT',
  },
  CHOOSE_PCP_FLOW: {
    NEW_PATIENT_DISCLAIMER: 'NEW_PATIENT_DISCLAIMER',
  },
  VISION_DICTIONARY: {
    DISCLAIMER_CONTENT: 'VISION_DICTIONARY_DISCLAIMER_CONTENT',
    DISCLAIMER_ADDL_CONTENT: 'VISION_DICTIONARY_DISCLAIMER_ADDL_CONTENT',
  },
  MNR_DISCLAIMER: {
    DENTAL_MNR_DISCLAIMER: 'DENTAL_MNR_DISCLAIMER',
    MEDICAL_FACILITY_MNR_DISCLAIMER: 'MEDICAL_FACILITY_MNR_DISCLAIMER',
    VISION_MNR_DISCLAIMER: 'VISION_MNR_DISCLAIMER',
    HEALTH_GRADES_MNR_DISCLAIMER_CONTENT:
      'HEALTH_GRADES_MNR_DISCLAIMER_CONTENT',
    DIRECTORY_MNR_DISCLAIMER: 'DIRECTORY_MNR_DISCLAIMER',
  },
  THINGS_TO_CONSIDER: {
    THINGS_TO_CONSIDER_MEMBER_ID_CONTENT:
      'THINGS_TO_CONSIDER_MEMBER_ID_CONTENT',
    THINGS_TO_CONSIDER_WAITING_PERIOD_CONTENT:
      'THINGS_TO_CONSIDER_WAITING_PERIOD_CONTENT',
    THINGS_TO_CONSIDER_MEMBER_ID_TITLE: 'THINGS_TO_CONSIDER_MEMBER_ID_TITLE',
    THINGS_TO_CONSIDER_WAITING_PERIOD_TITLE:
      'THINGS_TO_CONSIDER_WAITING_PERIOD_TITLE',
    THINGS_TO_CONSIDER_TITLE: 'THINGS_TO_CONSIDER_TITLE',
    THINGS_TO_CONSIDER_SUBTITLE: 'THINGS_TO_CONSIDER_SUBTITLE',
  },
  PCP_CHANGE_MESSAGE: {
    PCP_MESSAGE_FOR_LAG_TIME: 'PCP_MESSAGE_FOR_LAG_TIME',
    PCP_SUCCESS_MESSAGE: 'PCP_SUCCESS_MESSAGE',
  },
  UI_MESSAGING: {
    TIME_STAMP_CONTENT: 'TIME_STAMP_CONTENT',
    PRE_EFFECTIVE_NOTIFICATION: 'PRE_EFFECTIVE_NOTIFICATION',
    TABBED_SERVICES_FACILITIES_TITLE: 'TABBED_SERVICES_FACILITIES_TITLE',
    TABBED_SERVICES_FACILITIES_CONTENT: 'TABBED_SERVICES_FACILITIES_CONTENT',
    HELP_SECTION_TITLE: 'HELP_SECTION_TITLE',
    NETWORK_AFFILIATION_POPOVER_CONTENT: 'NETWORK_AFFILIATION_POPOVER_CONTENT',
    STATE_SPECIFIC_DISCLAIMER: 'STATE_SPECIFIC_DISCLAIMER',
    DENTAL_PROVIDER_NOT_AVAILABLE_CONTENT:
      'DENTAL_PROVIDER_NOT_AVAILABLE_CONTENT',
    PCP_ISPREEFFECTIVE_POPOVER_CONTENT: 'PCP_ISPREEFFECTIVE_POPOVER_CONTENT',
    PATIENT_CENTER_MEDICAL_HOMES_POPOVER_CONTENT:
      'PATIENT_CENTER_MEDICAL_HOMES_POPOVER_CONTENT',
    ESSENTIAL_COMMUNITY_PROVIDER_CONTENT:
      'ESSENTIAL_COMMUNITY_PROVIDER_CONTENT',
    VIEW_ID_CARD_HEADER: 'VIEW_ID_CARD_HEADER',
    VIEW_ID_CARD_CONTENT: 'VIEW_ID_CARD_CONTENT',
    VIEW_ID_CARD_BUTTON: 'VIEW_ID_CARD_BUTTON',
    EXPANDED_SEARCH_RADIUS_DESCRIPTION: 'EXPANDED_SEARCH_RADIUS_DESCRIPTION',
    UHC_DUAL_COMPLETE_PLAN_DISCLAIMER: 'UHC_DUAL_COMPLETE_PLAN_DISCLAIMER',
    UHC_CONNECTED_DISCLAIMER: 'UHC_CONNECTED_DISCLAIMER',
    FIND_CARE_DISCLAIMER: 'FIND_CARE_DISCLAIMER',
    TEXAS_HEALTH_STEPS_PROVIDER: 'TEXAS_HEALTH_STEPS_PROVIDER',
  },
};
